<template>
    <main>
        <!-- <section class="bg-zinc-800 py-60">
            <div class="container">
                <h1 class="text-white text-6xl font-bold text-balance">Exklusive Sonderkonditionen für Firmenwagen</h1>
            </div>
        </section> -->

        <section class="py-20">
            <div class="container">
                <FrontpageHeadline level="3" class="max-w-xl text-center mx-auto">
                    Interaktiver Firmenwagen-Finder. Für alle Branchen.
                </FrontpageHeadline>
            </div>
        </section>

        <section class="py-20">
            <div class="container grid gap-16">
                <FrontpageHeadline level="3" class="text-center mx-auto"> Top Deals </FrontpageHeadline>

                <div class="grid gap-4 grid-cols-3">
                    <template v-for="item in vehicles" :key="item.id">
                        <FrontpageVehicle class="col-span-full md:col-span-1" :item />
                    </template>
                </div>

                <FrontpageLoadMoreButton v-if="loadMoreButton" @click="loadMore" />
            </div>
        </section>

        <section class="py-20">
            <div class="container grid gap-16">
                <FrontpageHeadline level="3" class="text-center mx-auto">
                    Beste Marken - Beste Preise - Beste Deals
                </FrontpageHeadline>

                <FrontpageBrands />
            </div>
        </section>

        <section class="py-20">
            <div class="container grid gap-16">
                <FrontpageHeadline level="3" class="max-w-xl text-center mx-auto"> Ihre Vorteile </FrontpageHeadline>
                <div class="grid gap-4 grid-cols-4">
                    <div
                        class="border border-gray-400 rounded-lg py-12 px-6 text-center bg-white col-span-full md:col-span-2 lg:col-span-1"
                    >
                        <div class="bg-primary text-white size-12 rounded-full flex mx-auto mb-8">
                            <Icon name="person_add" :size="28" :weight="500" class="m-auto" />
                        </div>
                        <FrontpageHeadline level="5" class="mb-4">Ihre kostenfreie Mitgliedschaft</FrontpageHeadline>
                        <p class="mb-4 hyphens-auto">
                            Ihre Mitgliedschaft ist
                            <strong>unverbindlich, jederzeit kündbar</strong>
                            und <strong>kostenfrei</strong>. Keine Aufnahme- oder Jahresgebühren.
                        </p>
                        <p class="hyphens-auto">Sie gehen keinerlei Verpflichtung ein.</p>
                    </div>

                    <div
                        class="border border-gray-400 rounded-lg py-12 px-6 text-center bg-white col-span-full md:col-span-2 lg:col-span-1"
                    >
                        <div class="bg-primary text-white size-12 rounded-full flex mx-auto mb-8">
                            <Icon name="home" :size="28" :weight="500" class="m-auto" />
                        </div>
                        <FrontpageHeadline level="5" class="mb-4">Ihre kostenfreien Abrufscheine</FrontpageHeadline>
                        <p class="mb-4 hyphens-auto">
                            Als <strong>Mitglied</strong> sind Sie berechtigt und erhalten
                            <strong>alle Abrufscheine kostenfrei.</strong>
                        </p>
                        <p class="hyphens-auto text-gray-500 text-sm">
                            Bei Inanspruchnahme der ersten Leistung entsteht eine einmalige Datengebühr von nur 45 Euro
                            netto. Weitere Kosten entstehen nicht.
                        </p>
                    </div>

                    <div
                        class="border border-gray-400 rounded-lg py-12 px-6 text-center bg-white col-span-full md:col-span-2 lg:col-span-1"
                    >
                        <div class="bg-primary text-white size-12 rounded-full flex mx-auto mb-8">
                            <Icon name="home" :size="28" :weight="500" class="m-auto" />
                        </div>
                        <FrontpageHeadline level="5" class="mb-4">Ihr persönlicher Service-Scout Tim</FrontpageHeadline>
                        <p class="mb-4 hyphens-auto">
                            <strong>Erfahrung</strong> und <strong>Freundlichkeit</strong> trifft Verständnis und
                            Professionalität. Das ist Tim, Ihr Service-Scout.
                        </p>
                        <p class="hyphens-auto">Er freut sich darauf, Sie kennenzulernen.</p>
                    </div>
                    <div
                        class="border border-gray-400 rounded-lg py-12 px-6 text-center bg-white col-span-full md:col-span-2 lg:col-span-1"
                    >
                        <div class="bg-primary text-white size-12 rounded-full flex mx-auto mb-8">
                            <Icon name="home" :size="28" :weight="500" class="m-auto" />
                        </div>
                        <FrontpageHeadline level="5" class="mb-4">Ihr Mitglieder Online-Magazine</FrontpageHeadline>
                        <p class="hyphens-auto">
                            Verpassen Sie keine Angebote, indem Sie <strong>gratis</strong> die
                            <strong>OTK insight</strong> abonnieren. Sie können die OTK insight jederzeit wieder abbestellen.
                        </p>
                    </div>
                </div>

                <NuxtLink v-if="!account" to="/mitglied-werden" class="mx-auto">
                    <FrontpageButton>Mitglied werden</FrontpageButton>
                </NuxtLink>
            </div>
        </section>

        <section class="py-20">
            <div class="container grid grid-cols-12 gap-16">
                <div class="col-span-12 md:col-span-5 max-w-md">
                    <FrontpageHeadline level="3" class="max-w-xl mb-4"> Was uns antreibt </FrontpageHeadline>
                    <p class="mb-4 hyphens-auto">
                        Wir engagieren uns leidenschaftlich für Ihren wirtschaftlichen Erfolg. Denn Ihr Erfolg treibt auch
                        unser Wachstum voran.
                    </p>
                    <p class="hyphens-auto">
                        Eine Partnerschaft, die für alle Seiten Vorteile schafft - fair, nachhaltig und mit einem ehrlichen
                        Lächeln.
                    </p>
                </div>
                <div class="col-span-12 md:col-span-7">
                    <ul class="grid gap-8 grid-cols-2 font-medium">
                        <li class="flex gap-2">
                            <Icon name="check" :weight="700" class="text-primary" />
                            Seit 2017 erfolgreich im Markt
                        </li>
                        <li class="flex gap-2">
                            <Icon name="check" :weight="700" class="text-primary" />
                            Über 26.000 Mitglieder
                        </li>
                        <li class="flex gap-2">
                            <Icon name="check" :weight="700" class="text-primary" />
                            <div>
                                4,8
                                <Icon :size="22" name="star" fill class="text-primary -mr-0.5 -ml-1 align-top -mb-0.5" />
                                <Icon :size="22" name="star" fill class="text-primary -mr-0.5 -ml-1 align-top -mb-0.5" />
                                <Icon :size="22" name="star" fill class="text-primary -mr-0.5 -ml-1 align-top -mb-0.5" />
                                <Icon :size="22" name="star" fill class="text-primary -mr-0.5 -ml-1 align-top -mb-0.5" />
                                <Icon
                                    :size="22"
                                    name="star_half"
                                    fill
                                    class="text-primary -mr-0.5 -ml-1 align-top -mb-0.5"
                                />
                                <br />
                                <div>Kundenbewertung</div>
                            </div>
                        </li>
                        <li class="flex gap-2">
                            <Icon name="check" :weight="700" class="text-primary" />
                            Rahmenverträge mit einer Vielzahl attraktivster Marken
                        </li>
                        <li class="flex gap-2">
                            <Icon name="check" :weight="700" class="text-primary" />
                            40+ Jahre Erfahrung in der Automobilbranche
                        </li>
                        <li class="flex gap-2">
                            <Icon name="check" :weight="700" class="text-primary" />
                            Kompetenzpartner von DEHOGA
                        </li>

                        <li class="flex gap-2">
                            <Icon name="check" :weight="700" class="text-primary" />
                            Koopoeration mit LEASYS Full-Service-Leasing für sorgenfreie Mobilität
                        </li>

                        <li class="flex gap-2">
                            <Icon name="check" :weight="700" class="text-primary" />
                            Partnerschaft mit BVI
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    </main>
</template>

<script setup>
const { account } = useStore();

const { data } = await useFetch('/api/frontpage/site', {
    method: 'POST',
    body: {
        index: 0,
    },
});

const vehicles = ref(data.value.vehicles.items);
const loadMoreButton = ref(data.value.vehicles.total > 3);

async function loadMore() {
    const response = await $fetch('/api/frontpage/site', {
        method: 'POST',
        body: {
            index: vehicles.value.length,
        },
    });

    vehicles.value = [...vehicles.value, ...response.vehicles.items];

    if (!response.vehicles.items.length < 3) {
        loadMoreButton.value = false;
    }
}
</script>
