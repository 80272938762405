
import * as imageRuntime$uhdM0rcj4L from 'D:/GIT/handelskontor/providers/image.ts'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "image",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ],
  "quality": 70
}

imageOptions.providers = {
  ['image']: { provider: imageRuntime$uhdM0rcj4L, defaults: undefined }
}
        