import { joinRelativeURL } from 'ufo';
import type { ProviderGetImage } from '@nuxt/image';
import { createOperationsGenerator } from '#image';

const operationsGenerator = createOperationsGenerator();

export const getImage: ProviderGetImage = (src, { modifiers = {}, baseURL } = {}) => {
    if (!baseURL) {
        baseURL = useRuntimeConfig().public.siteUrl;
    }

    let parameters = operationsGenerator(modifiers);

    if(parameters) {
        parameters = '?' + parameters.replaceAll('/', '&')
    }

    return {
        url: joinRelativeURL( 'imager', src + parameters),
    };
};
