<template>
    <button class="flex items-center group hover:text-primary duration-150 mx-auto">
        <span class="mr-2"> Weitere Top Deals laden</span>

        <svg
            class="size-9 fill-gray-600 group-hover:text-primary group-hover:translate-x-4 duration-500"
            viewBox="0 -960 960 960"
        >
            <path d="m560-240-56-58 142-142H160v-80h486L504-662l56-58 240 240-240 240Z" />
        </svg>

        <div class="h-5 w-1 -ml-0.5 bg-primary group-hover:-translate-x-6 duration-500"></div>
    </button>
</template>

<script setup></script>
